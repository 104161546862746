import React from 'react';

import Layout from 'components/Layout';
import SEO from "../components/seo";

const NotFoundPage = () => {
  return (
    <Layout>
      <SEO/>
      <h1>Page Not Found</h1>
    </Layout>
  );
;}

export default NotFoundPage
